<template>
  <div>
    <div class="order-list" v-if="!myIsLoading">
      <el-empty :description="$t('ORDERS.NO_DATA')" v-if="isNoData"></el-empty>
      <template v-if="!isNoData">
        <div class="order-list-title">
          <h2>消費紀錄</h2>
        </div>
        <!--單張訂單-店家確認中 SR-->
        <div v-for="item in ordersPaginatedData.data" :key="item.order_id">
          <div
            :class="
            'order-item container bg-white shadow-sm py-2 ' +
            onGetListClass(item.status)
          "
          >
            <div class="order-header row no-gutters align-items-center border-bottom py-1">
              <div class="col-auto">
                <p class="text-muted mb-0">
                  <span class="material-icons-outlined">article</span>
                  {{ item.order_number }}
                </p>
              </div>
              <div class="col text-right">
                <button
                  class="btn btn-sm btn-outline-danger py-0 border-0 mb-1"
                  data-toggle="modal"
                  data-target="#popupConfirm"
                  v-if="item.status === 0"
                  @click="
                  state.dialogFormVisible = true;
                  state.cancelId = item.id;
                "
                >{{ $t("ORDERS.CANCEL.BUTTON") }}</button>
                <span class="separator text-gray pr-1" v-if="item.status === 0">|</span>
                <span class="order-status">
                  <template v-if="item.status === 0">
                    <!-- {{
                    $t("ORDERS.STATUS.NEW")
                    }} -->
                    訂單已送出
                  </template>
                  <template v-else-if="item.status === 1">
                    {{
                    $t("ORDERS.STATUS.PROCESSING")
                    }}
                  </template>
                  <template v-else-if="item.status === 3 || item.status === 6">
                    {{
                    $t("ORDERS.STATUS.CANCEL")
                    }}
                  </template>
                  <template v-else-if="item.status === 2">
                    {{
                    $t("ORDERS.STATUS.FINISH")
                    }}
                  </template>
                  <template v-else-if="item.status === 4">
                    {{
                    $t("ORDERS.STATUS.ABNORMAL")
                    }}
                  </template>
                  <template v-else-if="item.status === 5">
                    {{
                    $t("ORDERS.STATUS.REFUND")
                    }}
                  </template>
                </span>&emsp;
                <span class="badge badge-pill badge-primary" v-if="item.type === 0">預約</span>
                <span
                  class="badge badge-pill badge-success"
                  v-else-if="item.type === 1"
                >{{ $t("ORDERS.TO_GO") }}</span>
                <span
                  class="badge badge-pill badge-cyan"
                  v-else-if="item.type === 2"
                >{{ $t("ORDERS.ORDER_IN") }}</span>
                <span class="badge badge-pill badge-cerulean" v-else-if="item.type === 6">現場</span>
              </div>
            </div>
            <a :href="`/orders/${item.id}`" class="order-body d-block pt-1 pb-2 border-bottom">
              <div class="order-title row justify-content-between align-items-center py-2">
                <div class="col-10">
                  <h3 class="text-body text-truncate">{{ onGetProductName(item.order_items) }}</h3>
                </div>
                <div class="col-auto text-gray">
                  <span class="material-icons-outlined">arrow_forward_ios</span>
                </div>
              </div>
              <div class="order-summary row justify-content-between align-items-center">
                <div class="col">
                  <small class="text-muted d-inline-block">
                    {{
                    $t("ORDERS.GOOD_COUNT", {
                    count: onGetProductQuantity(item.order_items),
                    })
                    }}
                  </small>
                </div>
                <div class="col-auto">
                  <div
                    class="order-price font-weight-bold text-body display-4"
                  >${{ formatMoney(item.total) }}</div>
                </div>
              </div>
            </a>
            <div class="order-footer py-1 px-2 my-1 rounded">
              <span class="material-icons-outlined">schedule</span>
              <template v-if="item.type === 0">
                {{
                $t("ORDERS.MEAL_AT")
                }}
              </template>
              <template v-else>{{ $t("ORDERS.TOGO_AT") }}</template>
              ：
              <span v-if="item.order_diner">
                {{
                onChangeTime(item.order_diner.meal_at)
                }}
              </span>
            </div>
          </div>
        </div>
        <!--單張訂單-店家確認中 END-->
        <el-pagination
          background
          small
          v-model:currentPage="ordersPaginatedData.pagination.current_page"
          layout="prev, pager, next"
          :total="ordersPaginatedData.pagination.total"
          :page-size="ordersPaginatedData.pagination.pre_page"
          @current-change="handleCurrentChange"
          :pager-count="4"
          class="text-center"
        ></el-pagination>
        <div class="home-button">
          <!-- <el-button @click="goHome">回首頁</el-button> -->
          <router-link to="/home">
            <el-button>繼續購買</el-button>
          </router-link>
        </div>
      </template>
    </div>

    <el-dialog
      :title="$t('FORM.CANCEL_CONFIRM.TITLE')"
      v-model="state.dialogFormVisible"
      width="80%"
    >
      <div class="modal-body">
        <p>{{ $t("FORM.CANCEL_CONFIRM.DESCRIPTION") }}</p>
        <p class="text-danger">{{ $t("FORM.CANCEL_CONFIRM.DESCRIPTION_PS") }}</p>
      </div>
      <template #footer>
        <div class="dialog-footer modal-footer">
          <el-button
            type="primary"
            class="btn btn-main"
            @click="onCancelButton(state.cancelId)"
          >{{ $t("FORM.CANCEL_CONFIRM.CONFIRM") }}</el-button>
          <el-button
            class="btn btn-secondary"
            @click="state.dialogFormVisible = false"
          >{{ $t("FORM.CANCEL_CONFIRM.CANCEL") }}</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<style>
.el-dialog__header {
  border-bottom: 1px solid #dee2e6;
}
</style>
<script>
import { computed, reactive, getCurrentInstance, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { formatMoney } from "@/utils/tools";
import { zonedTimeToUtc, format } from "date-fns-tz";

export default {
  name: "OrdersView",
  props: {
    isLoading: {
      type: Boolean,
    },
  },
  emits: ["onLoading"],
  setup(props, { emit }) {
    const state = reactive({
      dialogFormVisible: false,
      cancelId: 0,
    });
    const { t } = useI18n();
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const router = useRouter();

    //一開始就要取資料
    const fetchAllProducts = (payload = {}) => {
      store.dispatch("orders/getOrders", payload);
    };
    fetchAllProducts();

    //computed: getter
    const isLoading = computed(() => store.getters["orders/isLoading"]);
    const myIsLoading = computed(() => {
      emit("onLoading", isLoading.value);
      return props.isLoading;
    });
    const isNoData = computed(() => store.getters["orders/isNoData"]);
    const orderList = computed(() => store.getters["orders/orderList"]);
    const ordersPaginatedData = computed(
      () => store.getters["orders/ordersPaginatedData"]
    );
    const isCanceling = computed(() => store.getters["orders/isCanceling"]);
    const canceledData = computed(() => store.getters["orders/canceledData"]);

    //methods
    const handleCurrentChange = (page) => {
      fetchAllProducts({ page: page });
    };
    const onCancelButton = (order_id) => {
      state.dialogFormVisible = false;
      store
        .dispatch("orders/cancelOrder", order_id)
        .then(() => {
          canceledData.value.is_cancel === true
            ? onCancelAlert("success")
            : onCancelAlert("error");
        })
        .catch(() => {
          onCancelAlert("error");
        });
    };
    const onChangeTime = (my_time) => {
      if (my_time !== null)
        return format(zonedTimeToUtc(my_time, "UTC"), "yyyy-MM-dd HH:mm:ss");
    };

    const onGetListClass = (status) => {
      let cls = [
        "order-new",
        "order-accepted",
        "order-finished",
        "order-canceled",
        "order-canceled",
        "order-finished",
        "order-canceled",
      ];
      return cls[status];
    };
    const onGetProductName = (items) => {
      let item_name = "";
      items.forEach((item) => {
        if (item.type !== 4 && item.type !== 5) {
          if (item_name !== "") item_name += "、";
          item_name += item.product_name;
        }
      });
      return item_name;
    };
    const onGetProductQuantity = (items) => {
      let count = 0;
      items.forEach((item) => {
        if (
          item.product_name !== "額外費用" ||
          item.product_name !== "服務費" ||
          item.product_name !== "運費" ||
          item.product_name !== "現場減價" ||
          item.product_name !== "外送費"
        )
          count += item.quantity;
      });
      return count;
    };
    const onCancelAlert = (type) => {
      let message =
        type === "success"
          ? t("ORDERS.CANCEL.SUCCESS")
          : t("ORDERS.CANCEL.FAIL");
      proxy.$message({
        type: type,
        message: message,
      });
    };

    const goHome = () => {
      // router.push("/");
      router.push("/member");
    };

    return {
      state,
      myIsLoading,
      isNoData,
      orderList,
      ordersPaginatedData,
      handleCurrentChange,
      onCancelButton,
      onGetListClass,
      onGetProductName,
      onGetProductQuantity,
      formatMoney,
      zonedTimeToUtc,
      format,
      onChangeTime,
      goHome,
    };
  },
};
</script>
